import React, { Component, Fragment, useState } from "react";
import ReactApexChart from "react-apexcharts";

export const StudentReportChart = ({data}) => {
    
    
    const [series_data, setSeries] = useState([
      {
        name: "Strong Correct",
        data: data["total_correct_Strong"],
      },
      {
        name: "Strong Wrong",
        data: data["total_Strong"].map(function (item, index) {
          return item - data["total_correct_Strong"][index];
        }),
      },
      {
        name: "Weak Correct",
        data: data["total_correct_Weak"],
      },
      {
        name: "Weak Wrong",
        data: data["total_Weak"].map(function (item, index) {
          return item - data["total_correct_Weak"][index];
        })
      },
    ]);
    const [options_data, setOptions] = useState({
      colors:["#2E67F8","#FFAD00", "#44D7A8", "#D22730"],
      chart: { type: "bar", height: 550, width: 500, stacked: true,
      zoom: {
        enabled: true
      }
    },
      plotOptions: {
        bar: { horizontal: false },
      },
      stroke: { width: 1, colors: ["#fff"] },
      title: { text: "" },
      xaxis: {
        categories: data["Test_date"],
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
      yaxis: {
        title: { text: undefined },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: { opacity: 1 },
      legend: { position: "top", horizontalAlign: "center", offsetX: 40 },
    });
    // const data_width = 500
    // if (data["Test_date"].length >= 5){
    //   data_width = data["Test_date"].length * 100
    // }
    // window.onload= document.getElementById("focus").focus();
    // window.scrollTo(0,document.querySelector(".scroll-container").scrollLeft)
    
    return(
      
      // <div className="scroll-container x.mandatory-scroll-snapping">
      // <div className="scroll-container">
      <div style={data["Test_date"].length > 5 ? {overflow: "auto", direction:"rtl", maxWidth: "1000px"} : {overflow: "auto",  maxWidth: "1000px"}}>
          <ReactApexChart
          
          options={options_data}
          series={series_data}
          type="bar"
          height={350}
          width={data["Test_date"].length < 5 ? 500 : data["Test_date"].length * 100}
        />
        {/* {window.dispatchEvent(new Event("resize"))} */}
    </div> 
    
    )
  };