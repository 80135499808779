import { Content } from './';
import React from 'react';
import { Outlet, Link } from "react-router-dom";

const EmptyLayout = ({ children, ...restProps }) => {
  console.log("I am in EmptyLayout", {children});
  return (
    <main className="cr-app bg-light" {...restProps}>
      <Content fluid>
        {children}
        <Outlet />
      </Content>
    </main>

  );
};
export default EmptyLayout;
