import logo200Image from '../assets/img/logo/logo_200.png';
import dslogoImage from '../assets/img/logo/dslogo.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';

class AuthForm extends React.Component {
  state = {
    username: '',
    password: '',
    confirm_password: '',
    Banner_Visibile: ""
  };


  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.isSignup) {
      if (this.state.password === this.state.confirm_password) {
        axios.post('/api/register/', { 'username': this.state.username, 'password': this.state.password })
          .then(resp => {
            console.log(resp.data)
            this.changeAuthState(STATE_LOGIN)
          })
          .catch(error => {
            alert(error.response.data.username)
          })
      }
      else {
        alert('Password not matched')
      }
    }
    else if (this.isLogin) {
      
      
      axios.post('/api/loginuser/', { 'username': this.state.username, 'password': this.state.password })
        .then(resp => {
          
          console.log('*******************************************************************',resp.data)
          
          localStorage.setItem('token', resp.data.token)
          sessionStorage.setItem('token', resp.data.token)
          localStorage.setItem('resDate', resp.data.user)
          localStorage.setItem("role",resp.data.role)
          
          if(resp.data.role =='ADMIN'){
            localStorage.setItem('tab_key', 1)
            window.location.href = '/Student'
          }else{
            fetch('/SchoolConfigPermission_Data',{
              headers: new Headers({
              'Authorization': "Token "+localStorage.getItem('token'), 
              })
            }).then(response=>response.json())
            .then((data)=>{
              let banner_visibile = data['Banner_Visibile']
              
              
              if (resp.data.role =='PARENT'){
                window.location.href = '/Parent'
              }
              else if (resp.data.role =='TEACHER'){
                if (data['Banner_Visibile'] == "Yes"){
                  localStorage.setItem('tab_key', 7)
                }else{
                  localStorage.setItem('tab_key', 10)
                }
                // localStorage.setItem('tab_key', 7)
                window.location.href = '/Student'
              }
              else{
                if (data['Banner_Visibile'] == "Yes"){
                  localStorage.setItem('tab_key', 7)
                }else{
                  localStorage.setItem('tab_key', 8)
                }
                  window.location.href = '/Student'
    
              }
            });
          }
          


          // if(resp.data.role =='ADMIN'){
          //   localStorage.setItem('tab_key', 1)
          //   window.location.href = '/Student'
          // }
          // else if (resp.data.role =='PARENT'){
          //   window.location.href = '/Parent'
          // }
          // else if (resp.data.role =='TEACHER'){

          //   localStorage.setItem('tab_key', 7)
          //   window.location.href = '/Student'
          // }
          // else{
          //   if (this.state.Banner_Visibile == "Yes"){
          //     localStorage.setItem('tab_key', 7)
          //   }else{
          //     localStorage.setItem('tab_key', 8)
          //   }
          //     window.location.href = '/Student'

          // }
         
        })
        .catch(error => {
          alert(error.response.data.non_field_errors)
        })

        
    }
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  handleChange(e) {
    if (e.target.name === 'password') {
      this.setState({
        password: e.target.value
      });
    }
    else if (e.target.name === 'username') {
      this.setState({
        username: e.target.value
      });
    }
    else if (e.target.name === 'confirm_password') {
      this.setState({
        confirm_password: e.target.value
      });
    }
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        {/* {showLogo && (
          <div className="text-center pb-4">
            <img
              src={dslogoImage}
              className="rounded"
              style={{ width: 60, height: 60, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )} */}
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input value={this.state.username} name='username' onChange={(e) => this.handleChange(e)} {...usernameInputProps} />
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input value={this.state.password} name='password' onChange={(e) => this.handleChange(e)} {...passwordInputProps} />
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input value={this.state.confirm_password} name='confirm_password' onChange={(e) => this.handleChange(e)} {...confirmPasswordInputProps} />
          </FormGroup>
        )}
        <FormGroup check>
          <Label check>
            <Input type="checkbox" />{' '}
            {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
          </Label>
        </FormGroup>
        <hr />
        <Button
          size="lg"
          className=" btn btn-fill btn-danger"
          block
          onClick={this.handleSubmit}>
          {this.renderButtonText()}
        </Button>

        <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {this.isSignup ? (
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
              <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                Signup
              </a>
            )}
          </h6>
        </div>

        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'UserName',
  usernameInputProps: {
    type: 'text',
    placeholder: 'User Name',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => { },
};

export default AuthForm;
