import React, { Component, Fragment, useState } from "react";

import ReactDatatable from "@ashvin27/react-datatable";
import { StudentReportChart } from "./StudentReportChart";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { shouldComponentUpdate } from "react-window";

class Historytab extends Component {
  shouldComponentUpdate = shouldComponentUpdate.bind(this);
  
  constructor(props) {
    super(props);
    
    this.state = {
      allsubject: {},
      testlist: [],
      test_number: 0,
    };
  }
  concatArr = (arr1, arr2, sep) =>
    arr1.map(function (num, idx) {
      return num.toString().concat(sep, arr2[idx].toString());
    });

  componentDidMount() {
    this.getCategoryGridData();
  }
  getCategoryGridData() {

    // if(localStorage.getItem("role")=="PARENT"){
    // // fetch("/getallhistory/"+this.props.parentCallback['id']+"/"+this.props.parentCallback['bundle']+"/"+this.props.parentCallback['subject'], {
    //   fetch("/getchildrenhistory/"+this.props.parentCallback, {
    //     headers: new Headers({
    //       Authorization: "Token " + localStorage.getItem("token"),
    //     }),
    //   })
    //     .then((response) => response.json())
    //     .then((data) => {
    //       console.log("data", data.all_sub_hist);
    //       this.setState({
    //         allsubject: data.all_sub_hist
    //       });
    //     });
    // }
    // else{
      fetch("/getallhistory/"+this.props.parentCallback['id']+"/"+this.props.parentCallback['bundle']+"/"+this.props.parentCallback['subject'], {
        // fetch("/getallhistory/"+this.props.parentCallback, {
          headers: new Headers({
            Authorization: "Token " + localStorage.getItem("token"),
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("data", data.all_sub_hist);
            this.setState({
              allsubject: data.all_sub_hist
            });
          });
    // }
    }


  Testnumber(t_n) {
    window.location.href = `/Testdetail/${t_n}/${this.props.parentCallback['id']}`;
    // window.location.href = `/Testdetail_jee/${t_n}/${this.props.parentCallback['id']}`;
  }
  render() {
    return (
     
      <div class=" col-md-7">
        <div class=" card ">
          <div class=" card-body">
            <p class=" card-text"></p>
            <div class=" author">
              <div class="tabs">
                
      <Tabs forceRenderTabPanel defaultIndex={1}>
   
    <TabPanel>

      <Tabs defaultIndex={2} onSelect={index => console.log("!!!!!!!!!!!!!!",index)}>
      {/* {localStorage.getItem('role')=="PARENT" &&
        <TabList>
           
            {Object.entries(this.state.allsubject).map(
                      ([sub, allchap], index) => ( 
                       
                        allchap["Test_date"] &&
                        <Tab>{sub}
                        {window.dispatchEvent(new Event("resize"))}</Tab>
                         
                
                      )
              )}           
        
        </TabList>
      } */}
        {console.log("before outer map",this.state.allsubject)}


        {Object.entries(this.state.allsubject).map(
                      ([sub, allchap], index) => (  
                        allchap["Test_date"]?
                      
                        <TabPanel>
                         
                         {console.log("inside outer  map",sub,typeof(sub),allchap)}
                        <div style={{marginBottom:"40px"}}>
                        <div><span class="heading" >Learning Progress</span></div>
                        
                         <div class="graph" style={allchap["Test_date"].length > 5 ? {marginBottom: "5px", width: "82%", boxShadow: "inset 0 0 5px grey",  borderRadius: "10px"} : {marginBottom: "5px", boxShadow: "inset 0 0 5px grey",  borderRadius: "10px"}}>
                          <div>
                            {allchap ?
                            <StudentReportChart data={allchap}/>:null}

                          </div>
                          </div>
                          
                          </div>
                          
                          {/* <div><span class="heading" >TEST HISTORY</span></div> */}
                          
                          {/* <div class="report custom-scrollbar">
                          
                          <table className="history-table">
                            
                            <tr class="header mostly-customized-scrollbar custom-scrollbar">
                              {" "}
                              <td class="thrid">Chapter Name</td>
                              <td class="first">Test NO</td>
                              <td class="first">Action </td>
                              <td class="thrid">Attempted Date</td>
                            </tr>
                            {allchap.Test_no.map((each_chap, i) => (
                              <tr>
                                <td>{allchap.chapter[i]}</td>
                                <td>Test-{each_chap}</td>
                                <td>
                                  <button
                                    className="btn1"
                                    onClick={() => {
                                      this.Testnumber(each_chap);
                                    }}
                                  >
                                    Detailed view{" "}
                                  </button>
                                </td>
                                <td>{allchap["Test_date"][i]}</td>
                              </tr>
                            ))}
                          </table>
                          </div> */}
                          <div><span class="heading" >VIEW REPORT</span></div>
                          
                          <div class="report custom-scrollbar">
                          
                          <table className="history-table">
                            
                            <tr class="header mostly-customized-scrollbar custom-scrollbar">
                              {" "}
                              <td class="thrid">Bundle Name</td>
                              <td class="first">View Report </td>
                              <td class="thrid">Attempted Date</td>
                            </tr>
                            {allchap.Test_no.map((each_chap, i) => (
                              <tr>
                                <td>{allchap.chapter[i]}</td>
                                <td>
                                  <button
                                    className="btn1"
                                    onClick={() => {
                                      this.Testnumber(each_chap);
                                    }}
                                  >
                                    Detailed view{" "}
                                  </button>
                                </td>
                                <td>{allchap["Test_date"][i]}</td>
                              </tr>
                            ))}
                          </table>
                          </div>
                          
                          
                          
                     
                      </TabPanel>: <div><h2 class="heading2" style={{width:"100%",marginLeft:"133%"}}>Please Take Test</h2></div>
                      )
              )}     

        
        
       
      </Tabs>
    </TabPanel>
   
  </Tabs>

  </div>
  </div>
  </div>
  </div>
  </div>
    );
  }
}
export default Historytab;
