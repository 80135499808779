import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';
import GAListener from './components/GAListener';
import { EmptyLayout, LayoutRoute, LayoutRoute1, MainLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import AuthPage from './pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Navigate, Route, Routes, Outlet } from 'react-router-dom';
// import { Historytab} from "./pages/History_tab";
import {isAuthenticated} from './utils/isAuthenticated';
import './styles/reduction.scss';
import { Content } from './components/Layout';
import {  useParams } from 'react-router-dom';
import Historytab from './pages/History_tab';
import { Component } from 'react';


// const Historytab = React.lazy(() => import('./pages/Historytab'));
const AuthModalPage =  React.lazy(() =>import('./pages/AuthModalPage'));
const Subchappage = React.lazy(() => import('./pages/getsubchap'));
const Chapterpage = React.lazy(() => import('./pages/Chapterpage'));
const Questionpage = React.lazy(() => import('./pages/Questionpage'));
const Studentlanding = React.lazy(() => import('./pages/Studentlanding'));
const Student = React.lazy(() => import('./pages/Studentlanding_student'));
// const Student1 = React.lazy(() => import('./pages/Student1'));
const StudentCourse = React.lazy(() => import('./pages/StudentCourse'));
const Testdetail = React.lazy(() => import('./pages/Testdetail'));
const StaffMaster = React.lazy(() => import('./pages/StaffMaster'));
const StudentMaster = React.lazy(() => import('./pages/StudentMaster'));
const AsmCourse = React.lazy(() => import('./pages/AsmCourse'));
const StaffAlert = React.lazy(() => import('./pages/StaffAlert'));
const AsmMaster = React.lazy(() => import('./pages/AsmMaster'));
const CourseMaster = React.lazy(() => import('./pages/CourseMaster'));
const KpiMaster = React.lazy(() => import('./pages/KpiMaster'));
const Questions = React.lazy(() => import('./pages/Questions'));
const AlertMaster = React.lazy(() => import('./pages/AlertMaster'));
const Studentalertedit = React.lazy(() => import('./pages/Studentalertedit'));
const StudentAlert = React.lazy(() => import('./pages/StudentAlert'));
const Asm = React.lazy(() => import('./pages/Asm'));
const Results = React.lazy(() => import('./pages/Results'));
const FeedBack = React.lazy(() => import('./pages/FeedBack'));
const Review = React.lazy(() => import('./pages/Review'));
const Grading = React.lazy(() => import('./pages/Grading'));
const Reviews = React.lazy(() => import('./pages/Reviews'));
const Configure = React.lazy(() => import('./pages/Configure'));
const Create_test = React.lazy(() => import('./pages/Create_test'));
const Create_offline_test = React.lazy(() => import('./pages/Create_offline_test'));
const Grand_test = React.lazy(() => import('./pages/Grand_test'));
const Challengeqn = React.lazy(() => import('./pages/Challengeqn'));
const Teacher_admin = React.lazy(() => import('./pages/Teacher_admin_result'));
const Teacher_admin_class = React.lazy(() => import('./pages/Teacher_admin_result_classreport'));
const Student_attend_report = React.lazy(() => import('./pages/Student_attend_report'));
const ChapterWiseClassProgress = React.lazy(() => import('./pages/ChapterWiseClassProgress'));
const Parent = React.lazy(() => import('./pages/Parent'));
const Children = React.lazy(() => import('./pages/Children_detail'));
const Inactiveqn = React.lazy(() => import('./pages/Inactiveqn'));
const Teacher_admin_OMR = React.lazy(() => import('./pages/Teacher_admin_OMR'));
const Latest_qn = React.lazy(() => import('./pages/Latest_qn'));
const Student_report = React.lazy(() => import('./pages/Student_report'));
const Editquestionpaper = React.lazy(() => import('./pages/Editquestionpaper'));
const Learning_progress_report = React.lazy(() => import('./pages/Learning_progress_report'));
const Finalgrandtest = React.lazy(() => import('./pages/Finalgrand_test'));
const Grand_Test_Result_Table = React.lazy(() => import('./pages/Grand_Test_Result_Table'));
const TeacherSelectonlineJeequstn = React.lazy(() => import('./pages/TeacherSelectonlineJeequstn'));



const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const PrivateRoute1 = ({component: Component, ...rest}) => {
  return (
    <Route {...rest}
        render = { ()=> 
          isAuthenticated() ? <Component> <Outlet /> </Component> : <Navigate to='/login-modal' />
        }
    />
  );
};

const PrivateRoute = ({component: Component, ...rest}) => {
  console.log("I am in Private Route", {...rest})
  return (

          isAuthenticated() ? <Component> <Outlet /> </Component> : <Navigate to='/login-modal' />
  );
};


class App extends React.Component {
  constructor(props) {
    super(props);
  }
 
  
  render() {
    // const Wrapper = (props,{component: Component, ...rest}) => {
    //   const params = useParams();
    //   console.log(props,params,"inside wrapper", {...rest})
    //   return (
    //   <Testdetail  />  {...{...props, match: {params}} }
    // }
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<EmptyLayout />} >
            <Route path="login" element={<AuthPage authState={STATE_LOGIN} />} /> 
            <Route exact path="login-modal" element={<AuthModalPage  authState={STATE_LOGIN}/>} />
            <Route path="Student1" element={<Student />}  /> 
            <Route path="login1" element={<AuthModalPage />}  /> 
          </Route>
          <Route path="/" element={<MainLayout breakpoint={this.props.breakpoint} />} >
            <Route path="Student" element={<Student />}  />
            {/* <Route path="Student1" element={<Student1 />}  /> */}
              <Route path="StudentMaster" element={<StudentMaster />} />
              <Route path="AsmCourse" element={<AsmCourse />} />
              <Route path="StudentAlert" element={<StudentAlert  />} />
              <Route path="AlertMaster" element={<AlertMaster />} />
              <Route path="StudentCourse" element={<StudentCourse />} />
              <Route path="StaffMaster" element={<StaffMaster  />} />
              <Route path="ASMMaster" element={<AsmMaster  />} />
              <Route path="Questions" element={<Questions />} />
              <Route path="CourseMaster" element={< CourseMaster  />} />
              <Route path="KPIMaster" element={< KpiMaster />} />
              <Route path="StaffAlert" element={< StaffAlert />} />
              <Route path="Asm" element={< Asm />} />
              <Route path="Results" element={< Results />} />
              <Route path="FeedBack" element={<FeedBack />} />
              <Route path="Review" element={<Review />} />
              <Route path="Grading" element={<Grading />} />
              <Route path="Reviews" element={<Reviews />} />
              <Route path="Configure" element={<Configure />} />
              <Route path="Studentlanding" element={<Studentlanding />} />
              <Route path="Parent" element={<Parent />} />
              <Route path="Create" element={<Create_test />} />
              <Route path="Grand_test" element={<Grand_test />} />
              <Route path="CreateOMR" element={<Teacher_admin_OMR />} />
              <Route path="Create_offline" element={<Create_offline_test />} />
              <Route path="Chapterpage/:class/:sub" element={<Chapterpage />} />
              <Route path="Questionpage/:class/:sub/:chap" element={<Questionpage />} />        
              <Route path="Studentalertedit/:id" element={<Studentalertedit />} />
              <Route path="Teacher/:class/:subject/:date/:b_name" element={<Teacher_admin />} />
              <Route path="Teacher-classreport/:class/:subject/:date/:b_name" element={<Teacher_admin_class />} />
              <Route path="Bundlewise_Progress/:school/:class/:subject/:test" element={<ChapterWiseClassProgress />} />
              <Route path="Testdetail/:t_no/:id" element={<Testdetail />} />
              <Route path="Children/:child_id/:name/:bundle/:sub" element={<Children />} />
              <Route path="Challengeqn/:teachername/:clas/:sub/:b_name" element={<Challengeqn />} />
              <Route path="Inactiveqn/:teachername/:clas/:sub/:b_name" element={<Inactiveqn />}   />
              <Route path="Student-attend-report/:class/:subject/:date/:b_name" element={<Student_attend_report />} />
              <Route path="latest_qn/:course/:subject/:questionpapername" element={<Latest_qn />} />
              <Route path="Student_report/:date" element={<Student_report />} />
              <Route path="Editquestionpaper/:cls/:sub/:chap/:scl/:bundle" element={<Editquestionpaper />} />
              <Route path="Learning_progress_report/:cls/:startdate/:enddate" element={<Learning_progress_report />} />
              <Route path="subchap/:classs/:scl/:qstnname" element={<Subchappage />} />
              <Route path="Finalgrand_test/:bundlename" element={<Finalgrandtest />} />
              <Route path="Grand_Test_Result_Table/:bundlename" element={<Grand_Test_Result_Table />} />
              <Route path="TeacherSelectonlineJeequstn/:date/:type/:scl/:totalqstn" element={<TeacherSelectonlineJeequstn />} />
              <Route path="TeacherSelectonlineJeequstn_upddate/:bundle/:selcount/:scl/:totalqstn" element={<TeacherSelectonlineJeequstn />} />
             
              

          </Route>
        </Routes>
      </BrowserRouter>
    );
  };
};

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
