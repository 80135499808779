import Avatar from '../Avatar';
import { UserCard } from '../Card';
import Notifications from '../Notifications';
import SearchInput from '../SearchInput';
import { notificationsData } from '../../demos/header';
import withBadge from '../../hocs/withBadge';
import React from 'react';
import dslogoImage from '../../assets/img/logo/BDSR-logo.png';
import aimlogoImage from '../../assets/img/logo/aiimlogo.png';
import axios from 'axios'
import { isAuthenticated } from '../../utils/isAuthenticated';
import signout from '../../assets/img/logo/signout.png';
import Modal from 'react-awesome-modal';
import Swal from "sweetalert2";
import SimpleReactValidator from 'simple-react-validator';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from '../../utils/bemnames';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);


class Header extends React.Component {
  constructor(props) {
    super(props);
    
  this.validator = new SimpleReactValidator({messages: {
    in: 'Passwords need to match!'
  }});
  
  }
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    clas:"",
    name:"",
    logo:"",
    visible:false,
    old_password:"",
    new_password:"",
    confirm_password:""
  };
  
  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };
  async componentDidMount(){
    let res= isAuthenticated()
    if (res){
      console.log("hereeeeeeeeee",)
      this.getname();
      fetch('/Check_password',{
        headers: new Headers({
          'Authorization': "Token "+localStorage.getItem('token'),
        })
      }).then(response=>response.json())
      .then((response)=>{
        console.log("Password_status",response,response.Password_status);
        this.setState({
          visible : response.Password_status
        });
      });
    }
   }
  getname=()=>{
    fetch('/getstudentdetails/',{
       headers: new Headers({
         'Authorization': "Token "+localStorage.getItem('token'),
       })
     }).then(response=>response.json())
     .then((data)=>{
       console.log("data",data)
       localStorage.setItem('class_name', data['Student'][0].grade)
       if(localStorage.getItem('role')=='PARENT'){
         this.setState({
             name:data['Name'],
             clas:" ",
             logo:data['Student'][0].school_name
         }); 
        }    
        else{
          this.setState({
            name:data['Name'],
            clas:data['Student'][0].grade,
            logo:data['Student'][0].school_name
        }); 
        }
     });
    //  if(localStorage.getItem('role')=='PARENT'){
    //   this.setState({
    //     clas:"Role: Parent"
    //   }) 
    // }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  logoutHandler = () => { 
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json',Authorization:"Token "+sessionStorage.getItem('token')},
      
  };
    console.log('api/logoutuser/',"Token "+localStorage.getItem('token'))
    
    fetch('api/logoutuser/',requestOptions
  
    ).then(function (response) {
      console.log("response*********",response);
      localStorage.clear()
      sessionStorage.clear()
      window.location.href = '/login-modal'
  
  })
   
  }
  // openModal() {
  //   this.setState({
  //         visible : true
  //     });    
  // }
  closeModal(){
    this.setState({
      visible : false
  });
  }
  Changepassword() {
    if (this.validator.allValid()) {
      axios.post('/Reset_password',
      {
        Old_pw:this.state.old_password,
        New_pw:this.state.new_password
      },
    { headers: { Authorization:"Token "+localStorage.getItem('token')}}
      ).then(response=>{
        console.log("Status",response,response.data['Status']);
        let password_status=response.data['Status']
        this.message_box(password_status);
      }).catch(function (error) {
          console.log("Error",error);
      }); 
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }   
  }
  message_box=password_status=>{
    console.log("lavanya checking",password_status)
    if(password_status==true){
      Swal.fire({  
        title: 'Alert!',  
        html: 'Password Changed'
        })
    }
    else{
      Swal.fire({  
        title: 'Alert!',  
        html: 'Password Not Changed'
        })
    }
  }
 
  render() {
    const { isNotificationConfirmed } = this.state;
    console.log("i am in header")

    return (
      <Navbar light expand >
        <img src={dslogoImage} className="pr-2" alt=""/>
        <span className="pr-3">e-Practice</span>
        
        <div style={{marginLeft:"auto",width:"2%"}}>
        <span >  {this.state.logo=='Sree Gokulam'?<img
                        src={process.env.PUBLIC_URL+'/Sree_Gokulam.png'}
                        width=""
                        height=""
                        className="pr-5"
                        // onError={(event) => event.target.src = ''}
                        onError={(event) => event.target.style.display = 'none'}
                      />:<img
                      src={process.env.PUBLIC_URL+'/'+this.state.logo+'.png'}
                      width=""
                      height=""
                      className="pr-5"
                      // onError={(event) => event.target.src = ''}
                      onError={(event) => event.target.style.display = 'none'}
                    />
                      }
        </span>
        </div>
          {/* <img src={process.env.PUBLIC_URL+'/Profile.jpg'} alt="Avatar" width="50px" marginLeft="22%" marginTop="-3%"/> */}
          <div>
          <Avatar
                    onClick={this.toggleUserCardPopover}
                    className="can_click"
                  />
            <Modal id="d_box" visible={this.state.visible} width="641" height="400" effect="fadeInUp" onClickAway={this.closeModal.bind(this)}>
                    <div>
                        <h1 class="reset_heading">Reset Password</h1>
                        
                          <label class="reset_labels" >
                            Old Password:
                          </label>
                          <input style={{marginLeft:"30px"}}
                            type="password"
                            name="old_password"
                            value={this.state.old_password}
                            onChange={this.onChange}
                            placeholder="Old Password"
                            class="reset_input"
                          />
                          <p class="requied">{this.validator.message('Old Password', this.state.old_password,'required|')}</p>
                          <br></br>
                          <label class="reset_labels" >
                            New Password:
                          </label>
                          <input style={{marginLeft:"23px"}}
                            type="password"
                            name="new_password"
                            value={this.state.new_password}
                            onChange={this.onChange}
                            placeholder="New Password"
                            class="reset_input"
                          />
                          <p class="requied">{this.validator.message('New Password', this.state.new_password,'required|')}</p>
                          <br></br>
                          <label class="reset_labels" >
                            Confirm Password:
                          </label>
                          <input style={{marginLeft:"-8px"}}
                            type="password"
                            name="confirm_password"
                            value={this.state.confirm_password}
                            onChange={this.onChange}
                            placeholder="Confirm Password"
                            class="reset_input"
                          />
                          <p class="requied">{this.validator.message('Confirm Password', this.state.confirm_password,`required|in:${this.state.new_password}`)}</p>
                          <br></br>
                        <p><button class="reset_btn" style={{marginLeft:"18%"}} onClick={this.Changepassword.bind(this)}>Reset Password</button>
                        
                        <button class="reset_btn" style={{marginLeft:"71%",marginTop:"-9%"}} onClick={this.closeModal.bind(this)}>Close</button></p>
                    </div>
              </Modal>
          </div>
          <h5 class="pr-4" >
                  
                  {this.state.name}
                  <br/>{this.state.clas}
                  <br/>Role: {localStorage.getItem('role')}
                  <br/>
                  <div style={{marginLeft:"-24%"}}>
                  <img src={signout} width="16%" />
                  <button class="btn2" onClick={this.logoutHandler}>Signout</button>
                  </div>
                  </h5>

      </Navbar>

    );
  }
}

export default Header;
