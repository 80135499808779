import dslogoImage from '../../assets/img/logo/BDSR-logo.png';
import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import SourceLink from '../SourceLink';
import React from 'react';
import { FaGithub } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdLocalGroceryStore,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdPages,
  MdRadioButtonChecked,
  MdSend,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWeb,
  MdWidgets,
  MdLibraryBooks,
  MdDns,
  MdGroupAdd,
  MdLocalShipping,
  
  MdReceipt,
  MdPayment
} from 'react-icons/md';
import { FaMoneyCheckAlt } from "react-icons/fa";
import {AiOutlineShoppingCart,AiOutlineDeploymentUnit} from "react-icons/ai";
import { GiPublicSpeaker,GiPayMoney} from "react-icons/gi";
import { GoReport} from "react-icons/go";
import { HiDocumentReport } from "react-icons/hi";
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';

const navComponents = [
  { to: '/StudentMaster', name: 'Student', exact: false, Icon: AiOutlineDeploymentUnit},
  { to: '/StaffMaster', name: 'Staff', exact: false, Icon:MdDns,},
  { to: '/CourseMaster', name: 'Course', exact: false, Icon: MdGroupAdd },
  { to: '/KPIMaster', name: 'KPI', exact: false, Icon:MdLibraryBooks },
  { to: '/ASMMaster', name: 'Assessment', exact: false, Icon:MdLocalGroceryStore,},
  { to: '/Studentlanding', name:'Studentlanding', exact: false, Icon: MdWeb }, 
];
const navComponents1 = [
  { to: '/Student', name:'Studentlanding', exact: false, Icon: MdWeb }, 
];

const navContents = [
  { to: '/AlertMaster', name: 'Alert', exact: false, Icon: MdReceipt},
  { to: '/DifficultyMaster', name: 'Difficulty', exact: false, Icon:GiPayMoney}, 
];

const pageContents = [
  { to: '/StudentCourse', name: 'students', exact: false, Icon: HiDocumentReport},
  { to: '/Staff', name: 'Staff', exact: false, Icon:AiOutlineShoppingCart},
  { to: '/Questions', name: 'Questions', exact: false, Icon: GiPublicSpeaker},
  { to: '/AsmCourse', name: 'Assesments', exact: false, Icon: MdWeb }, 
];
const staffContents = [
   { to: '/StaffAlert', name: 'Alert', exact: false, Icon: MdInsertChart },
   { to: '/Grading', name: 'Grading', exact: false, Icon: MdInsertChart },
   { to: '/Reviews', name: 'Reviews', exact: false, Icon: MdInsertChart },
   { to: '/Configure', name: 'Configure', exact: false, Icon: MdInsertChart },
 ];
 const studentContends = [
  { to: '/StudentAlert', name: 'Alert', exact: false, Icon: MdWidgets },
  { to: '/review', name: 'Review', exact: true, Icon: MdDashboard },
  { to: '/Asm', name: 'Asm', exact: true, Icon: MdDashboard },
  { to: '/Results', name: 'Results', exact: true, Icon: MdDashboard }, 
  { to: '/FeedBack', name: 'FeedBack', exact: true, Icon: MdDashboard }, 
 ];
const navItems = [
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    // alert(localStorage.getItem('resDate'))
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')}/>
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              {/*<img
                src={dslogoImage}
                width="200px"
                
                className="pr-2"
                alt=""
              />*/}
              <span className="text-white">
                
              </span>
            </SourceLink>
          </Navbar>
          {localStorage.getItem('role') == "admin" &&
            <Nav vertical>
              {navItems.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}

              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('Components')}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <MdExtension className={bem.e('nav-item-icon')} />
                    <span className=" align-self-start">Menu Master</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenComponents
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenComponents}>
                {navComponents.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      //className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>

              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('Contents')}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <MdExtension  className={bem.e('nav-item-icon')} />
                    <span className="">Admin</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenContents
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenContents}>
                {navContents.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      //className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>

              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('Pages')}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <MdExtension 

                    className={bem.e('nav-item-icon')} />
                    <span className="">Course</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenPages
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenPages}>
                {pageContents.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      //className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('staffContents')}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <MdExtension className={bem.e('nav-item-icon')} />
                    <span className=" align-self-start">Staff</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenstaffContents
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenstaffContents}>
                {staffContents.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      //className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse> 
              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('studentContends')}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <MdExtension className={bem.e('nav-item-icon')} />
                    <span className=" align-self-start">Students</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenstudentContends
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenstudentContends}>
                {studentContends.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      //className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e('nav-item-icon')} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse> 
              
            </Nav>
          }

          {localStorage.getItem('role') == "student" &&
             <Nav vertical>
             {navItems.map(({ to, name, exact, Icon }, index) => (
               <NavItem key={index} className={bem.e('nav-item')}>
                 <BSNavLink
                   id={`navItem-${name}-${index}`}
                   className="text-uppercase"
                   tag={NavLink}
                   to={to}
                   activeClassName="active"
                   exact={exact}
                 >
                   <Icon className={bem.e('nav-item-icon')} />
                   <span className="">{name}</span>
                 </BSNavLink>
               </NavItem>
             ))}

             <NavItem
               className={bem.e('nav-item')}
               onClick={this.handleClick('Components')}
             >
               <BSNavLink className={bem.e('nav-item-collapse')}>
                 <div className="d-flex">
                   <MdExtension className={bem.e('nav-item-icon')} />
                   <span className=" align-self-start">Menu Master</span>
                 </div>
                 <MdKeyboardArrowDown
                   className={bem.e('nav-item-icon')}
                   style={{
                     padding: 0,
                     transform: this.state.isOpenComponents
                       ? 'rotate(0deg)'
                       : 'rotate(-90deg)',
                     transitionDuration: '0.3s',
                     transitionProperty: 'transform',
                   }}
                 />
               </BSNavLink>
             </NavItem>
             <Collapse isOpen={this.state.isOpenComponents}>
               {navComponents1.map(({ to, name, exact, Icon }, index) => (
                 <NavItem key={index} className={bem.e('nav-item')}>
                   <BSNavLink
                     id={`navItem-${name}-${index}`}
                     //className="text-uppercase"
                     tag={NavLink}
                     to={to}
                     activeClassName="active"
                     exact={exact}
                   >
                     <Icon className={bem.e('nav-item-icon')} />
                     <span className="">{name}</span>
                   </BSNavLink>
                 </NavItem>
               ))}
             </Collapse>             
           </Nav>
          }
          

        </div>
     </aside>
    );
  }
}

export default Sidebar;
